import styled from 'styled-components';

import { PADDING } from '../../style';
import * as colors from '../../style/colors';

const getColor = ({ background }) => background ? colors[background] : colors.ORANGE;

export const Wrapper = styled.div`
  background: ${getColor};
  padding: ${PADDING * 2}px ${PADDING}px;
  display: flex;
  position: relative;
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;
